import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"  // Optional: smooth scroll animation
    });
  };
  return (
    <footer className="bg-white  shadow-lg  text-center text-surface/75 border-t lg:text-left">
      {/* Top border and social links */}
      {/* <div className="flex items-center justify-center border-b-2 border-neutral-200 p-6 dark:border-white/10 lg:justify-between">
        <div className="me-12 hidden lg:block">
          <span>Get connected with us on social networks:</span>
        </div>
        <div className="flex justify-center">
          
          <a href="#!" className="me-6 [&>svg]:h-4 [&>svg]:w-4">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 320 512">
              <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
            </svg>
          </a>
        
        </div>
      </div> */}

      {/* Main content sections */}
      <div className="mx-6 py-10 text-center md:text-left">
        <div className="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          {/* TW Elements section */}
          <div>
            <h6 className="mb-4 flex items-center justify-center font-semibold uppercase md:justify-start">
              <span className="me-3 [&>svg]:h-4 [&>svg]:w-4">
             <Link onClick={handleScrollToTop} to="/">
              <img
            className="h-[80px] w-full"
             src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/Core_Spectators_1.png?v=1719032020"/>
        </Link>
              </span>
             
            </h6>
            <p>
            To empower your vision with unparalleled creativity and technical prowess, transforming ideas into impactful realities.   </p>
          </div>

          {/* Products section */}
          <div>
            <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
              Company
            </h6>
            <p className="mb-4 hover:text-red-500">
              <Link onClick={handleScrollToTop} to="/About">About Us</Link>
            </p>
            <p className="mb-4 hover:text-red-500">
              <Link onClick={handleScrollToTop} to="/Privacy">Privacy Policy</Link>
            </p>
            <p className="mb-4 hover:text-red-500">
              <Link onClick={handleScrollToTop} to="/Why-us">Why Us</Link>
            </p>
            <p className='hover:text-red-500'>
              <Link onClick={handleScrollToTop} to="/contact-us">Contact Us</Link>
            </p>
          </div>

          {/* Useful links section */}
          <div>
            <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
              Services
            </h6>
            <p className='mb-4 hover:text-red-500'>
              <Link onClick={handleScrollToTop} to="/Services">Programing & Tech</Link>
            </p>
            <p className='mb-4 hover:text-red-500'>
              <Link onClick={handleScrollToTop} to="/Services">Video & Animation</Link>
            </p>
            <p className='hover:text-red-500'>
              <Link onClick={handleScrollToTop} to="/Services">Graphics & Branding</Link>
            </p>
           
          </div>

          {/* Contact section */}
          <div>
            <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
              Contact
            </h6>
            <p className="mb-4 flex items-center justify-center md:justify-start">
              <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FF3130">
                  <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                  <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
                </svg>
              </span>
              Islamabad, Pakistan
            </p>
            <p className="mb-4 flex items-center justify-center md:justify-start">
              <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FF3130">
                  <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                  <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                </svg>
              </span>
              contact@corespectators.com
            </p>
            <p className="mb-4 flex items-center justify-center md:justify-start">
              <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FF3130">
                  <path fillRule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clipRule="evenodd" />
                </svg>
              </span>
              +92 333 5136060
            </p>
            <p className="flex items-center justify-center md:justify-start">
              <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FF3130">
                  <path fillRule="evenodd" d="M12 1.5a10.5 10.5 0 110 21 10.5 10.5 0 010-21zm0 3a7.5 7.5 0 100 15 7.5 7.5 0 000-15z" clipRule="evenodd" />
                  <path d="M12 6.75a4.5 4.5 0 100 9 4.5 4.5 0 000-9zM12 8a3 3 0 100 6 3 3 0 000-6z" />
                </svg>
              </span>
              Open: 24 Hours
            </p>
          </div>
        </div>
      </div>

      {/* Bottom section */}
      <div className="border-t-2  p-6">
        <p className="text-center">
          &copy; 2024 Core Spectators. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
